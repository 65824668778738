<template>
  <div class="page">
    <!-- 引入的返回头组件 -->
    <titlebar :name="name" :is_custom_back="true" @goBackCustom="goBlack" />
    <div class="succession_box">
      <div class="box_top">
        <div class="pd_rt10">收银员：{{ info.staff_name || "" }}</div>
        <div class="pd_rt10">工号：{{ info.staff_num || "" }}</div>
        <div>登录时间：{{ info.login_time || "" }}</div>
      </div>
      <div class="box_btm">
        <div class="btm_box">
          <div class="btm_name">销售额(元)</div>
          <div class="btm_num">{{ info.sale.sale_all || 0 }}</div>
          <div class="btm_btm">
            <div>现金</div>
            <div>{{ info.sale.xj || 0 }}</div>
          </div>
          <div class="btm_btm">
            <div>会员余额</div>
            <div>{{ info.sale.hyye || 0 }}</div>
          </div>
          <div class="btm_btm">
            <div>微信</div>
            <div>{{ info.sale.wx || 0 }}</div>
          </div>
          <div class="btm_btm">
            <div>支付宝</div>
            <div>{{ info.sale.zfb || 0 }}</div>
          </div>
          
          <div class="btm_btm">
            <div>云闪付</div>
            <div>{{ info.sale.ysf || 0 }}</div>
          </div>
        </div>
        <div class="btm_box">
          <div class="btm_name">会员储值(元)</div>
          <div class="btm_num">{{ info.member.recharge_amount || 0 }}</div>
          <div class="btm_btm">
            <div>现金</div>
            <div>{{ info.member.m_xj || 0 }}</div>
          </div>
          <div class="btm_btm">
            <div>微信</div>
            <div>{{ info.member.m_wx || 0 }}</div>
          </div>
          <div class="btm_btm">
            <div>支付宝</div>
            <div>{{ info.member.m_zfb || 0 }}</div>
          </div>
          <div class="btm_btm">
            <div>云闪付</div>
            <div>{{ info.member.m_ysf || 0 }}</div>
          </div>
          <div class="btm_btm">
            <div>赠送金额</div>
            <div>{{ info.member.give_amount || 0 }}</div>
          </div>
        </div>
        <div class="btm_box">
          <div class="btm_name">其他</div>
          <div class="btm_num" style="color: #f8f8f8;">0</div>
          <div class="btm_btm">
            <div>新增会员</div>
            <div>{{ info.other.new_member || 0 }}个</div>
          </div>
          <div class="btm_btm">
            <div>订单数量</div>
            <div>{{ info.other.new_order || 0 }}笔</div>
          </div>
          <div class="btm_btm">
            <div>充值笔数</div>
            <div>{{ info.other.new_recharge || 0 }}笔</div>
          </div>
          <div class="btm_btm">
            <div>毛利额</div>
            <div>{{ info.other.new_cost || 0 }}元</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 下方按钮区 -->
    <div class="btm_btn_box">
      <div class="print"></div>
      <div class="print_btn_box">
        <!-- <van-button class="print_btn" @click="goToSellingGoodsList()"
          >销售商品列表</van-button
        > -->
        <div></div>
        <van-button class="print_btn getout" @click="openDialog">交接班并登出</van-button>
      </div>
    </div>

    <popupOfDialog
      v-if="showDialog"
      :type="dialogType"
      :title="dialogTitle"
      :message="dialogMsg"
      :data="dialogData"
      @closePopDialog="closePopDialog"
      @confirmPopDialog="confirmPopDialog"
    ></popupOfDialog>
  </div>
</template>

<script>
import titlebar from "@/views/component/titlebar";
import { Dialog, Toast } from "vant";
import popupOfDialog from "../component/popupOfDialog.vue";
export default {
  created() {
    this.name = this.$route.query.name; //拿到传过来的标题
    console.log("name:", this.name);
    this.getInfo();
  },
  components: {
    titlebar: titlebar,
    popupOfDialog,
  },
  data() {
    return {
      info: {
        order: {},
        pay: {},
        sale: {},
        member: {},
        other: {},
      },
      showDialog: false,
      dialogTitle: "",
      dialogMsg: "",
      dialogType: "",
      dialogData: {},
    };
  },
  methods: {
    goBlack() {
      if (this.$route.query.active) {
        this.$router.replace({
          name: 'featureSet',
          query: {
            name: this.$route.query.name,
            active: this.$route.query.active,
          }
        })
      } else {
        this.$router.go(-1);
      }
    },
    confirmPopDialog() {
      this.successionLogout();
      this.closePopDialog();
    },
    closePopDialog(data) {
      this.showDialog = false;
    },
    openDialog() {
      this.dialogTitle = "提示";
      this.dialogMsg = `您确定要进行交接班并登出操作吗？`;
      this.dialogType = '4';
      this.dialogData = {};
      this.showDialog = true;
    },
    // 交接班并退出登录
    async successionLogout() {
      if (this.info.is_handover_print == 1) {
        this.printSuccessionInfo();
      }
      this.$api.Logout({
        print_data: this.info.print_data
          ? this.info.print_data
          : "printdata",
      }).then((res) => {
        if (res.code == 1) {
          localStorage.removeItem("token");
          localStorage.removeItem("user_type");
          localStorage.removeItem("role_str");
          if (localStorage.shop_id == 1) {
            localStorage.removeItem('shop_only_code');
          }
          this.$store.commit("updateShopCart", []);
          this.$store.commit("undateCurrentSwee_code", '');
          this.$router.replace({ name: "login" });
        }
      });
    },
    // 打印
    printSuccessionInfo() {
      if (this.info.print_data) {
        let printData = JSON.parse(this.info.print_data);
        this.$printFunc.printReceipt(printData);
      }
      // if (this.info.print_data && typeof KissSunmiPrint !== "undefined") {
      //   let printData = JSON.parse(this.info.print_data);
      //   printData.cp = "1";
      //   //开启打印
      //   console.log(printData)
      //   KissSunmiPrint.transactionPrint([printData])
      //     .then(function (msg) {
      //       console.log("============================3" + msg);
      //     })
      //     .catch(function (error) {
      //       console.log("============================4" + error);
      //     });
      // }
    },
    // 获取交接班页面数据
    getInfo() {
      this.$api
        .handoverShow({
          shop_id: localStorage.getItem("shop_id"),
        })
        .then((res) => {
          console.log(res, "getInfo信息");
          if (res.code == 1) {
            this.info = res.data.list || {
              order: {},
              pay: {},
              sale: {},
              member: {},
              other: {},
            };
            this.info.member = res.data.list.member || {};
            this.info.other = res.data.list.other || {};
          } else {
            this.$toast(res.msg);
            this.info = {
              order: {},
              pay: {},
              sale: {},
              member: {},
              other: {},
            }
          }
        });
    },
    goToSellingGoodsList() {
      this.$router.push({
        path: "/sellingGoodsList",
        query: { name: "sellingGoodsList" },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.pd_rt10 {
  padding-right: 10rem;
}
.page {
  width: 100%;
  height: 100%;
  background-color: #f8f8f8;
  position: relative;
  .succession_box {
    // display: flex;
    // flex-direction: column;
    // align-items: center;
    overflow-y: scroll;
    height: calc(100% - 23.1rem);
    .box_top {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 10rem;
      width: 100%;
      font-size: 2.8rem;
      font-weight: bold;
      color: #333;
      border-bottom: 0.1rem solid #e5e5e5;
      margin-bottom: 6.2rem;
    }
    .box_btm {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(3, 33.33%);
      .btm_box {
        padding: 0 6.8rem;
      }
      .btm_name {
        font-size: 3rem;
        margin-bottom: 1.4rem;
      }
      .btm_num {
        font-size: 5rem;
        font-weight: bold;
        margin-bottom: 8.5rem;
      }
      .btm_btm {
        font-size: 2.8rem;
        margin-bottom: 5rem;
        display: flex;
        justify-content: space-between;
        position: relative;
      }
    }
  }
  .btm_btn_box {
    position: fixed;
    bottom: 0;
    width: calc(100vw - 13.5rem);
    height: 14.8rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    right: 0;
    .print {
      font-size: 2.6rem;
      color: #1588f5;
      font-weight: bold;
      margin-left: 8rem;
    }
    .print_btn_box {
      width: 50rem;
      display: flex;
      justify-content: space-between;
      margin-right: 8rem;
    }
    .print_btn {
      width: 21.7rem;
      height: 6rem;
      border: 0.1rem solid #e5e5e5;
      font-size: 2rem;
      font-weight: 400;
    }
    .getout {
      background-color: #1588f5;
      color: #fff;
    }
  }
  .btm_btm::after {
    content: "";
    top: 3rem;
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
    width: 100%;
    height: 1.3rem;
    background-color: #e5e5e5;
    border-radius: 0.7rem;
  }
}
</style>
